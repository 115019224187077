
import { createApp } from 'vue'
import App from './app.vue';
import { Options, Vue } from 'vue-class-component';
import HeaderView from '@/components/HeaderView.vue'; // @ is an alias to /src
import FooterView from '@/components/FooterView.vue'; // @ is an alias to /src
import { useRoute, useRouter } from "vue-router";

export default({
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      // ここはいつかDB用意して引っ張ってくるようにしたい(Vuexで状態管理したい。。)
      products: ["アップカメラ",],
    };
  },
  methods: {
  }
})
