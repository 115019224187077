<template>
    <header>
    <div class="wrapper site-header__wrapper">
      <a class="header-title">〜ツナツナ.COM〜</a>
        <!-- <nav class="nav">
          <ul class="nav__wrapper">
            <li class="nav__item"><a href="#">自己紹介</a></li>
            <li class="nav__item"><a href="#">このサイトについて</a></li>
            <li class="nav__item"><a href="#">個人成果物</a></li>
          </ul>
        </nav> -->
    </div>
    </header>
</template>


<style>
header {
  position: relative;
  background-color: black; 
  height: 70px;
  border-bottom: black 3px solid;
  width: 100%;
}

.header-title {
  font-weight: bold;
  font-size: 30px;
  color: white;
  padding: 10px;
  margin: auto;
}

.nav{
  margin: auto;
}

.nav__wrapper{
  list-style: none;
}

@media (min-width: 600px) {
  /* .header-title {
  font-weight: bold;
  font-size: 40px;
  font-weight: bold; 
  color: white;
  padding:20px 0;
  margin: auto;
} */

.site-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0; } 
}

@media (min-width: 600px) {
  .nav__wrapper {
    display: flex; } }
 
@media (max-width: 599px) {
  .nav__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #d9f0f7;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; }
    .nav__wrapper.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0); } }
 
.nav__item a {
  display: block;
  padding: 1.5rem 1rem; 
  color: white;
}
 
</style>