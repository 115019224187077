<template>
   <footer>
    <!-- <ul class="footer-menu">
     <li>自己紹介 ｜</li>
     <li>このサイトについて ｜</li>
     <li>個人成果物</li>
    </ul> -->
    <p>© Yasufumi Tsunaki 2022</p>
  </footer>
</template>


<style>
footer{
  /* position: fixed; */
  position: relative;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: black;
  color: white;
  padding: 20px;

    /* width: 100%;
    height: 30px;
    background-color: black;
    color: white;
    text-align: center;
    padding: 15px 0; */
}

/* ul.footer-menu li {
 display: inline;
} */
</style>