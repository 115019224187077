<template>
  <div>
    <router-link to="/">
      <button class="btn-back">
        戻る
      </button>
    </router-link>
  </div>    
</template> 

<style>
.btn-back{
  padding: 5px;
  margin: 5px;
  width: 10vw;
  border: none;
  border-radius: 10px;
  background-color: grey;
  color: white;
  font-size: 1.5vw;
}

</style>