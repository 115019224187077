
import { Options, Vue } from 'vue-class-component';
import HeaderView from '@/components/HeaderView.vue';
import FooterView from '@/components/FooterView.vue';
import BackToHomeButton from '@/components/BackToHomeButton.vue';
import faviconHelper from "@/helpers/favicon";

export default{
  components: { BackToHomeButton },
  name: "Product",
  methods: {
    startTimer: function() {
      faviconHelper.updateUpCamera();
    },
  }
}
