export default {
    // faviconをdefaultに変更する
    updateDefault: function() {
      updateFavicon("default.ico");
    },

    updateUpCamera: function() {
      updateFavicon("up_camera.png");
    },
  };
  
  function updateFavicon(fileName: string) {
    const link = document.querySelector("link[rel*='icon']");
    if(link !== null){
      console.log(link);
      // link.href = process.env.BASE_URL + fileName;
    }
  }