
import { Options, Vue } from 'vue-class-component';
import HeaderView from '@/components/HeaderView.vue'; // @ is an alias to /src
import FooterView from '@/components/FooterView.vue'; // @ is an alias to /src

export default({
  components: {
    HeaderView,
    FooterView,
  },
})
